<template>

  <div :class="'search ' + (showed ? ' showed ' : '')">
       
    <input 
      type="text" 
      @keyup="start"     
      v-click-outside="hide"
      v-model="keyword" 
      :placeholder="placeholder"
      >

    <i class="icon-close" v-if="keyword?.length" @click="remove"></i>
    <i class="icon-search" @click="start"></i>



    <div class="res"> 
      <div class="items">
        <template
          v-for="item in results"
          >
          <div 
            class="item" 
            @click="choose(item, $event)"
            >
            <img class="av" v-if="item.avatar" :src="item.avatar"> 
            {{ item.name ? item.brand_name + ' - ' + item.name : item.brand_name }}                  
          </div>
        </template>
      </div> 
    </div>

  </div>
  
</template>

<script setup>
 
import debounce from 'lodash/debounce';
const emits = defineEmits(['choose'])
const props = defineProps({
  queryType: {
    type: String
  },
  placeholder: {
    type: String
  },
  val: {
    type: String
  },
})
const { t } = useI18n()
const { $api } = useNuxtApp()

var keyword = ref(props.val);
var results = ref([]);

var showed = ref(false);
  
 
const remove = () => {
  keyword.value = '';
  emits('search', keyword.value);
}


const hide = function(){    
  showed.value = false
}

const choose = function(item,event){    
//  console.log('item');
//  console.log(item);
  emits('choose', item);
  event.stopPropagation();
  keyword.value = '';
  showed.value = false;
}

const start = debounce((event) => { 
  console.log('start search');
  keyword.value = event.target.value;

  showed.value = true;      
  $api.getUrl('/search/brandproduct', {
    query: keyword.value, 
    category: 'seed',
    type: 'any'
  })
  .then((response) => {        
    results.value = response?.items;
  })
  .catch(function (error) { 
    
  });
  
}, 300);


watch(() => props.val, (newVal, oldVal) => {

  console.log('newProps');
  console.log(newVal);
  keyword.value = newVal;

});


</script>

<style scoped>

/* search */

.search{
  position: relative;
}
.search input{
  border-radius: 30px;
  padding: 0.4rem 3.2rem 0.4rem 0.6rem;
}
/* results */
 
 .res{
   display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
    border: 1px solid rgba(34, 36, 38, 0.15);
    min-width: 360px;
    width: 100%;
    right: 0;
    overflow-y: auto;
    max-height: 500px;
 }
 .showed .res{
   display: inherit;
 }
 .cats{}
 .cats .cat{
  display: flex;
  min-width: 120px;
  width: 100%;
 }
 .cats .cat .name{
  background-color: whitesmoke;
  padding: 0.4rem 1rem;
  font-weight: bold;
  flex-shrink: 0;
  width: 30%;
 }
 .items{
   display: flex;
   flex-direction: column;
   border-left: 1px whitesmoke solid; 
   border-top: 1px whitesmoke solid;
   width: 100%;
 }
 .items .item{
  padding: 0.4rem 1rem;
  width: calc(100% - 2rem);
  cursor: pointer;
  gap: 5px;
  display: flex;
  align-items: center;
  width: 100%;
    font-weight: bold;
}
 .items .item .av{ 
    border-radius: 3px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
    flex-shrink: 0;
}

.search .icon-close,
.search .icon-search{    
  position: absolute;
  top: 0;
  display: flex;
  width: 26px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: gray;
}
.search .icon-close{
  right: 27px;
}
.search .icon-search{
  right: 4px;
}

</style>
